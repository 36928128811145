<template>
    <v-container>
        <Header
            :registryStatus="getOfferStatuses"
            :recordIndex="getOfferDetails.index"
            :registryTitle="getOffersRegistry.name"
            :registryUrl="'offers'"
            :actualStatus="getOfferDetails.status"
            :avatarData="getOfferDetails.createdBy"
            @status-changed="changeStatus"
        ></Header>
        <Main :items="items" class="p-relative">
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('offers:editionOffer')"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editOffer()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('offers:editOffer') }}</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
    </v-container>
</template>

<script>
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Deployments from '../../../components/Registries/Offers/Tabs/Deployments/Deployments.vue'
import Information from '../../../components/Registries/Offers/Details/Information.vue'
import AEContent from './../../../components/Registries/Offers/Modal/Content'
import Buttons from './../../../components/Registries/Offers/Modal/Buttons'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        Deployments,
        Information,
        AEContent,
        Buttons,
    },
    data() {
        return {
            open: false,
            items: [
                {
                    name: this.$i18n.t("offers:customerRating"),
                    id: 'deployments',
                    icon: 'mdi-star-outline',
                    component: Deployments,
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getOfferStatuses',
            'getOffersRegistry',
            'getOfferDetails',
        ]),
    },
    methods: {
        ...mapMutations([
            'setOfferDetails',
            'setOfferStatuses',
            'setOfferRegistry',
            'setOfferModal',
            'clearOfferErrors',
            'clearRepresentativesInput',
            'clearOffersModal'
        ]),
        ...mapActions(['fetchOffer', 'updateOfferStatus']),
        changeStatus(status) {
            this.updateOfferStatus({ status, id: this.getOfferDetails._id })
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        async editOffer() {
            this.open = true
            this.clearOfferErrors()
            this.setOfferModal()
        },
        closeModal() {
            this.open = false
            this.clearOfferErrors()
            this.clearRepresentativesInput()
            this.clearOffersModal()
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchOffer', { id: to.params.id, next })
    },
}
</script>
