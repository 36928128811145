<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="12" md="12">
                <v-row>
                    <v-col cols="12" md="12">
                        <Separator :text="$t('offers:customerRating')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6 ">
                        <v-textarea
                            style="width: 50%;"
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(getOfferDetails, 'deployment.devicesAmount', '')
                            "
                            :label="$t('offers:departments.devicesAmount')"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            style="width: 50%;"
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(getOfferDetails, 'deployment.devicesLocation', '')
                            "
                            :label="$t('offers:departments.devicesLoc')"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(getOfferDetails, 'deployment.websiteOpinion', '')
                            "
                            :label="$t('offers:departments.websiteOpinion')"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            rows="1"
                            auto-grow
                            readonly
                            :value="$get(getOfferDetails, 'deployment.socialMedia', '')"
                            :label="$t('offers:departments.socialMedia')"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            rows="1"
                            auto-grow
                            readonly
                            :value="$get(getOfferDetails, 'deployment.emailFormat', '')"
                            :label="$t('offers:departments.emailFormat')"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            style="width: 50%;"
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(
                                    getOfferDetails,
                                    'deployment.customerShareCapital',
                                    ''
                                )
                            "
                            :label="$t('offers:departments.customerShareCapital')"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            style="width: 50%;"
                            rows="1"
                            auto-grow
                            readonly
                            :value="
                                $get(getOfferDetails, 'deployment.scopeActivities', '')
                            "
                            :label="$t('offers:departments.scopeActivities')"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="6">
                        <v-textarea
                            style="width: 50%;"
                            rows="1"
                            auto-grow
                            readonly
                            :value="$get(getOfferDetails, 'deployment.industry', '')"
                            :label="$t('offers:departments.industry')"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <Separator :text="$t('offers:summary')" />
                            </v-col>
                        </v-row>
                        <v-textarea
                            rows="1"
                            auto-grow
                            readonly
                            :value="$get(getOfferDetails, 'deployment.summary', '')"
                            :label="$t('offers:departments.summary')"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    computed: {
        ...mapGetters(['getOfferDetails']),
    },
}
</script>
