<template>
    <v-container>
        <Information/>
        <Modal
            :title="$t('offers:editDeploys')"
            :open="open"
            :height="'520'"
            v-on:close="closeModal()"
        >
            <Content slot="AEContent" />
            <Buttons
                @closeModal="open = false"
                :action="'edit'"
                slot="buttons"
            />
        </Modal>
        <div class="d-flex justify-end buttons--details">
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        dark
                        v-on="on"
                        v-if="edit()"
                        small
                        @click="editDeploys()"
                        class="buttons--add margin--1"
                    >
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('offers:edit') }}</span>
            </v-tooltip>
        </div>
    </v-container>
</template>
<script>
import Content from './Modal/Content.vue'
import Buttons from '../../../Offers/Modal/Buttons.vue'
import Information from '../../../Offers/Tabs/Deployments/Details/Information.vue'

import { mapMutations } from 'vuex'

export default {
    components: {
        Content,
        Buttons,
        Information
    },
    data() {
        return {
            open: false,
        }
    },
    methods: {
        ...mapMutations([
            'setOfferModal',
            'clearOfferErrors',
            'clearRepresentativesInput',
        ]),
        edit() {
            return this.checkPermissions('UPDATE')
        },

        editDeploys() {
            this.open = true
            this.clearOfferErrors()
            this.setOfferModal()
        },
        closeModal() {
            this.open = false
            this.clearOfferErrors()
            this.clearRepresentativesInput()
        },
    },
}
</script>
