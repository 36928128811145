<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getOfferErrors.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getOfferErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('offers:customerRating')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getOfferErrors.stepOne.validation,
                                ]"
                                editable
                                step="2"
                                >{{ $t('offers:summary') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="$t('offers:customerRating')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-textarea
                                            v-model="
                                                getOfferModal.deployment
                                                    .devicesAmount
                                            "
                                            :label="`${$t(
                                                'offers:departments.devicesAmount'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-textarea
                                            v-model="
                                                getOfferModal.deployment
                                                    .devicesLocation
                                            "
                                            :label="`${$t(
                                                'offers:departments.devicesLoc'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" >
                                        <v-textarea
                                            v-model="
                                                getOfferModal.deployment
                                                    .websiteOpinion
                                            "
                                            :label="`${$t(
                                                'offers:departments.websiteOpinion'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-textarea
                                            v-model="
                                                getOfferModal.deployment
                                                    .socialMedia
                                            "
                                            :label="`${$t(
                                                'offers:departments.socialMedia'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" >
                                        <v-textarea
                                            v-model="
                                                getOfferModal.deployment
                                                    .emailFormat
                                            "
                                            :label="`${$t(
                                                'offers:departments.emailFormat'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-textarea
                                            v-model="
                                                getOfferModal.deployment
                                                    .customerShareCapital
                                            "
                                            :label="`${$t(
                                                'offers:departments.customerShareCapital'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-textarea
                                            v-model="
                                                getOfferModal.deployment
                                                    .scopeActivities
                                            "
                                            :label="`${$t(
                                                'offers:departments.scopeActivities'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-textarea
                                            v-model="
                                                getOfferModal.deployment
                                                    .industry
                                            "
                                            :label="`${$t(
                                                'offers:departments.industry'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content class="pb-0 pt-4" step="2">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="$t('offers:summary')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="py-1">
                                        <v-textarea
                                            v-model="
                                                getOfferModal.deployment.summary
                                            "
                                            :label="`${$t(
                                                'offers:departments.summary'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            hideContacts: true,
            disabled: false,
            showMenu: false,
            step: 1,
            editable: true,
        }
    },
    computed: {
        ...mapGetters(['getOfferModal', 'getOfferErrors']),
    }
}
</script>
